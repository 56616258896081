@font-face {
  font-family: 'proto-mono';
  src: url("../assets/fonts/ProtoMono-Regular.ttf");
}

@font-face {
  font-family: 'hangyaboly';
  src: url("../assets/fonts/Hangyaboly.ttf");
}

// ObjObj theme as default
:root {
  --primary-color: #9fbc9f;
  --secondary-color: #b54038;
  --background-color: #171717;
  --text-color: #ebefeb;
  --stroke-color: #b54038;
  --font-style: proto-mono;

  --navbar-width: 5rem;
}

// Not That Alex Jones overrides
.NTAJ {
  --primary-color: #3777FF;
  --secondary-color: #f3f174;
  --background-color: #FFC0CB;
  --text-color: #1D201F;
  --font-style: hangyaboly;
}