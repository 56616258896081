#about {
  width: 100%;
  min-height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .ntaj-conatiner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width: 993px) {
      width: 80%;
      align-self: center;
    }

    img {
      top: 10vh;
      left: 0;
      width: auto;
      height: auto;
      z-index: 1;
      pointer-events: none;
      width: 150%;
      margin-left: calc(100vw/25);
      
      @media only screen and (min-width: 576px) {
        width: 100%;
        margin: 0;
      }

      @media only screen and (min-width: 768px) {
        width: auto;
        align-self: center;
      }
    }
  }

  span {
    z-index: 3;
    font-weight: 600;
    font-size: medium;
    font-family: var(--font-style);
    filter: drop-shadow(10px 5px 0 var(--secondary-color));
    color: var(--text-color);
    overflow: hidden;
    
    // &.ntaj-text {
    //   display: inline;
    //   // flex-wrap: wrap;
    //   // justify-content: flex-start;
    //   // align-items: center;
    //   filter: none;
    //   font-weight: 800;
    //   font-size: larger;
    //   max-width: 60%;
    //   margin-bottom: 5%;
    //   background-color: var(--secondary-color);

    //   i {
    //     margin: 0 7px;
    //   }
      
    //   &:nth-child(even) {
    //     margin-left: auto;
    //   }
    // }

    &:not(.ntaj-text):after {
      content: '';
      display: block;
      height: 50px;
      width: 100%;
    }
  
    p {
      display: inline;
      visibility: hidden;
    }

    .cursor {
      position: absolute;
      display: inline-block;
      width: 0.2ch;
      height: 2ch;
      filter: none;
      line-height: 0;
      background-color: white;
      margin-left: 2px;
      animation: blink 1s steps(1) infinite;
    }
  }
  
}

@keyframes blink {
  50% {
    background-color: transparent;
  }
}