@import 'variables';

body {
  background-color: transparent;
  color: var(--text-color);
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

#App {
  width: calc(100% - (var(--navbar-width) * 2));
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 var(--navbar-width);

  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y proximity;
  -webkit-overflow-scrolling: touch;
  
  .snap-point {
    scroll-snap-align: start;
    min-height: 100vh;
    z-index: 2;
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
}

* {
  scrollbar-color: auto var(--background-color);
}