#photos {
  position: relative;
  width: 100%;
  min-height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: absolute;
    padding: 0;
    max-height: 70%;
    border-radius: 2px;
    overflow: hidden;
    image-rendering: pixelate;
    border: 10px solid white;
    border-bottom: 40px solid white;
    // transition: transform 1s ease;
    filter: drop-shadow(20px 15px 10px black);
    width: 100%;
    
    @media only screen and (min-width: 576px) {
      max-width: 70%;
      margin: 0;
    }
    
    @media only screen and (min-width: 768px) {
      width: auto;
      align-self: center;
    }
  }
}