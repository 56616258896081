.mix-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  max-width: 15rem;
  aspect-ratio: 1 / 1;
  cursor: pointer;
  border: 1px solid var(--primary-color);
  transition: all 0.3s ease-in-out;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin-y: 500px;
  box-shadow: 0px 2px 0px rgb(0, 0, 0, 0.5);


  @media (max-width: 768px){
    width: 100%;
    aspect-ratio: 1 / 1;
  }

  .mix-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 3;
  }

  .vinyl {
    width: 110%;
    aspect-ratio: 1 / 1;
    transition: transform 0.3s ease-in-out;
    z-index: 2;
  }

  .mix-play-icon {
    position: absolute;
    width: 50%;
    height: 50%;
    opacity: 0.3;
    z-index: 4;
  }

  &.in-focus {
    .mix-play-icon {
      opacity: 1;
    }
    
    .vinyl {
      transform: translateX(5rem) rotateX(0deg);
    }
  }
}

#mix-player-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 768px){
    flex-direction: column;
    justify-content: space-evenly;
  }

  .mix-container {
    position: relative;
    margin-right: 1rem;

    @media (max-width: 768px) { 
      margin-right: 0;
    }
  }
  
  #mix-player-info {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
    height: 100%;

    @media (max-width: 768px){
      width: 100%;
      height: 12rem;
      margin: 0 1rem;
    }
    
    
    .mix-info {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0;
      color: var(--secondary-color);
      font-family: var(--font-style);
      font-size: 1rem;
      font-weight: 600;

      p {
        margin: 0;
      }
      
      @media (max-width: 768px){
        align-self: center;
        font-size: 1rem;
      }
    }
    
    .mix-player {
      position: relative;
      margin: 0;
      width: 100%;
      height: 75%;
      border: none;
    }
  }
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1rem;
  color: var(--secondary-color);
  cursor: pointer;
  align-self: flex-end;
}

@media (max-width: 768px){
  .close-icon.large {
    display: none;
  }
}

@media (min-width: 768px){
  .close-icon.small {
    display: none;
  }
}