.obj-repeat-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  overflow: hidden;
  background-color: var(--background-color);

  .object-repeat-line {
    position: relative;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    overflow: hidden;
  }
  
  .align-right {
    width: 100%;
  }
}

.object-repeat-word {
  color: var(--primary-color);
  opacity: 0.3;
  user-select: none;

  &.color-me {
    color: var(--secondary-color);
    opacity: 1;
  }

  &.highlight {
    color: white;
    opacity: 1;
  }
}
