.nav {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: var(--navbar-width);
  height: 100%;

  .mood-switch-button {
    background: none;
    font-size: xx-large;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    width: 100%;
    margin-bottom: 50px;
  }

  .ntaj-nav {
    display: flex;
    align-items: center;
    width: 100%;
    color: var(--primary-color);
    -webkit-text-stroke: 2px var(--text-color);
    font-size: 3rem;
    font-family: var(--font-style);
    writing-mode: vertical-rl;
    text-orientation: upright;
    margin: 0;
    padding: 0;
  }
}

.nav-icon {
  position: relative;
  width: 40px;
  height: 40px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  background-color: var(--background-color);
  cursor: pointer;
  z-index: 4;

  &:hover {
    background-color: var(--secondary-color);
  }

  .nav-icon-symbol {
    width: 50%;
    height: 50%;
    color: var(--primary-color);
  }
}

.current-page-icon {
  background-color: var(--secondary-color);
}