.ntaj-container {
  width: 200%;
  height: 400%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-color);

  div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 200%;
    height: calc(100% / 40);
    transform: rotate(45deg);
    transform-origin: center;

    img {
      transform: rotate(-45deg);
      height: 100%;
      
      &.shaking {
        &:nth-child(5n + 1) {
          animation: shake1 2s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
        }
        &:nth-child(5n + 2) {
          animation: shake2 2s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
        }
        &:nth-child(5n + 3) {
          animation: shake3 2s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
        }
        &:nth-child(5n + 4) {
          animation: shake4 2s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
        }
        &:nth-child(5n + 5) {
          animation: shake5 2s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
        }
      }
    }
  }
}

@keyframes shake1 {
  0%, 100% {
    transform: rotate(-45deg) translate(0, 0);
  }
  10% {
    transform: rotate(-45deg) translate(0.5px, -0.3px);
  }
  20% {
    transform: rotate(-45deg) translate(-0.7px, 0.4px);
  }
  30% {
    transform: rotate(-45deg) translate(0.3px, 0.8px);
  }
  40% {
    transform: rotate(-45deg) translate(-0.4px, -0.5px);
  }
  50% {
    transform: rotate(-45deg) translate(0.6px, -0.2px);
  }
  60% {
    transform: rotate(-45deg) translate(-0.5px, 0.7px);
  }
  70% {
    transform: rotate(-45deg) translate(0.4px, -0.6px);
  }
  80% {
    transform: rotate(-45deg) translate(-0.3px, -0.4px);
  }
  90% {
    transform: rotate(-45deg) translate(0.7px, 0.3px);
  }
}

@keyframes shake2 {
  0%, 100% {
    transform: rotate(-45deg) translate(0, 0);
  }
  8% {
    transform: rotate(-45deg) translate(0.4px, -0.5px);
  }
  18% {
    transform: rotate(-45deg) translate(-0.6px, 0.6px);
  }
  28% {
    transform: rotate(-45deg) translate(0.5px, 0.2px);
  }
  38% {
    transform: rotate(-45deg) translate(-0.3px, -0.4px);
  }
  48% {
    transform: rotate(-45deg) translate(0.7px, -0.3px);
  }
  58% {
    transform: rotate(-45deg) translate(-0.5px, 0.4px);
  }
  68% {
    transform: rotate(-45deg) translate(0.3px, -0.7px);
  }
  78% {
    transform: rotate(-45deg) translate(-0.4px, 0.5px);
  }
  88% {
    transform: rotate(-45deg) translate(0.6px, -0.2px);
  }
}

@keyframes shake3 {
  0%, 100% {
    transform: rotate(-45deg) translate(0, 0);
  }
  12% {
    transform: rotate(-45deg) translate(-0.5px, 0.3px);
  }
  22% {
    transform: rotate(-45deg) translate(0.3px, -0.6px);
  }
  32% {
    transform: rotate(-45deg) translate(-0.7px, 0.2px);
  }
  42% {
    transform: rotate(-45deg) translate(0.5px, -0.4px);
  }
  52% {
    transform: rotate(-45deg) translate(-0.4px, 0.7px);
  }
  62% {
    transform: rotate(-45deg) translate(0.2px, -0.5px);
  }
  72% {
    transform: rotate(-45deg) translate(-0.6px, 0.4px);
  }
  82% {
    transform: rotate(-45deg) translate(0.4px, -0.3px);
  }
  92% {
    transform: rotate(-45deg) translate(-0.2px, 0.5px);
  }
}

@keyframes shake4 {
  0%, 100% {
    transform: rotate(-45deg) translate(0, 0);
  }
  7% {
    transform: rotate(-45deg) translate(0.3px, -0.4px);
  }
  17% {
    transform: rotate(-45deg) translate(-0.5px, 0.2px);
  }
  27% {
    transform: rotate(-45deg) translate(0.4px, 0.3px);
  }
  37% {
    transform: rotate(-45deg) translate(-0.2px, -0.7px);
  }
  47% {
    transform: rotate(-45deg) translate(0.6px, -0.5px);
  }
  57% {
    transform: rotate(-45deg) translate(-0.3px, 0.6px);
  }
  67% {
    transform: rotate(-45deg) translate(0.2px, -0.4px);
  }
  77% {
    transform: rotate(-45deg) translate(-0.6px, 0.5px);
  }
  87% {
    transform: rotate(-45deg) translate(0.4px, -0.3px);
  }
}

@keyframes shake5 {
  0%, 100% {
    transform: rotate(-45deg) translate(0, 0);
  }
  6% {
    transform: rotate(-45deg) translate(-0.4px, 0.5px);
  }
  16% {
    transform: rotate(-45deg) translate(0.2px, -0.3px);
  }
  26% {
    transform: rotate(-45deg) translate(-0.5px, 0.4px);
  }
  36% {
    transform: rotate(-45deg) translate(0.3px, -0.6px);
  }
  46% {
    transform: rotate(-45deg) translate(-0.6px, 0.2px);
  }
  56% {
    transform: rotate(-45deg) translate(0.5px, -0.4px);
  }
  66% {
    transform: rotate(-45deg) translate(-0.3px, 0.6px);
  }
  76% {
    transform: rotate(-45deg) translate(0.4px, -0.2px);
  }
  86% {
    transform: rotate(-45deg) translate(-0.2px, 0.5px);
  }
}
