#contact {
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  z-index: 2;

  .contact-icon {
    width: 100px;
    height: 100px;
    margin: 10px;
    border: 5px solid var(--primary-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    cursor: pointer;

    svg {
      width: 50%;
      height: 50%;
      text-decoration: none; 
      color: var(--primary-color);
    }
  }
}
