#mixes {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  perspective: 5000px;
  transform-style: preserve-3d;
  z-index: 2;

  .selected-mix {
    width: 100%;
    height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    @media (max-width: 768px){
      height: 55%;
    }
  }

  .unselected-mixes {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 3rem;

    @media (max-width: 768px){
      margin-top: 0;
    }

    .mix-container {
      position: relative;
      width: 20%;
      transform-origin: center;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
    }
  }
}